import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import { sendEmailVerification } from 'firebase/auth';

import { auth } from '../firebaseSetup';

const VerifyEmailPage = () => {
  const { t } = useTranslation();

  const handleVerificationEmail = () => {
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}/onboarding`,
      });
    }
  };

  useEffect(() => {
    handleVerificationEmail();
  }, []);

  return (
    <div data-testid="verify-email-page" className="w100p text-center">
      <h1 className="mt120">{t('VERIFY-EMAIL-PAGE.VERIFY-YOUR-EMAIL')}</h1>
      <h3 className="text-faded mt32">{t('VERIFY-EMAIL-PAGE.BEFORE-WE-GET-STARTED')}</h3>
      <h3 className="text-faded">
        {t('VERIFY-EMAIL-PAGE.WEVE-SENT-A-CODE')} <span className="text-blue">{auth.currentUser?.email}</span>
      </h3>
      <img className="mt48" src="/images/email-icon.svg" alt="email"></img>
      <div></div>
      <Button className="btn-white mt32" onClick={handleVerificationEmail}>
        {t('VERIFY-EMAIL-PAGE.RESEND-EMAIL')}
      </Button>
    </div>
  );
};

export default VerifyEmailPage;
